import JSONTableEditor from 'json-table-editor'
import tailSelect from 'tail.select';

document.addEventListener('DOMContentLoaded', () => {
    const itemField = document.getElementById("Form_InvoiceForm_InvoiceItems");

    if(itemField) {
        const data = itemField.value ? JSON.parse(itemField.value) : '';
        const table = new JSONTableEditor(
            "#InvoiceItemsTable", {
                formatOptions: [],
                maxColumns: 4,
                defaultColumns: 4,
                defaultRows: 3,
            }, data);

        table.view.container.addEventListener('dataChanged',function(){
            console.log(table.model.tableData)
            itemField.value = JSON.stringify(table.model.tableData);
        })
    }

    const selects = [...document.querySelectorAll("select")]

    selects.forEach((select) => {
        tailSelect(select, {
            search: true
        })
        //     .on("change", (item, stage) => {
        //     select.form.submit();
        // });
    })

    const deleteItems = document.querySelectorAll("[data-behaviour=\"delete-button\"]");

    deleteItems.forEach((item) => {
        item.addEventListener("click", (event) => {
            if(!confirm("Eintrag löschen?")) {
                event.preventDefault();
                return false;
            }
        })
    })



});

